const cookiesCount = 25;

const cookiesSettings = {
  min: 1,
  max: 24,
}

const statuses = {
  inPlay: 0,
  won: 1,
  lost: 2,
}

const coeffs = [
  [1.04, 1.09, 1.14, 1.19, 1.25, 1.32, 1.39, 1.47, 1.56, 1.67, 1.79, 1.92, 2.08, 2.27, 2.5, 2.78, 3.12, 3.57, 4.17, 5.0, 6.25, 8.33, 12.5, 25.0],
  [1.09, 1.19, 1.3, 1.43, 1.58, 1.75, 1.96, 2.21, 2.5, 2.86, 3.3, 3.85, 4.55, 5.45, 6.67, 8.33, 10.71, 14.29, 20.0, 30.0, 50.0, 100.0, 300.0],
  [1.14, 1.3, 1.49, 1.73, 2.02, 2.37, 2.82, 3.38, 4.11, 5.05, 6.32, 8.04, 10.45, 13.94, 19.17, 27.38, 41.07, 65.71, 115.0, 230.0, 575.0, 2300.0],
  [1.19, 1.43, 1.73, 2.11, 2.61, 3.26, 4.13, 5.32, 6.95, 9.27, 12.64, 17.69, 25.56, 38.33, 60.24, 100.4, 180.71, 361.43, 843.33, 2530.0, 12650.0],
  [1.25, 1.58, 2.02, 2.61, 3.43, 4.57, 6.2, 8.59, 12.16, 17.69, 26.54, 41.28, 67.08, 115.0, 210.83, 421.67, 948.75, 2530.0, 8855.0, 53130.0],
  [1.32, 1.75, 2.37, 3.26, 4.57, 6.53, 9.54, 14.31, 22.12, 35.38, 58.97, 103.21, 191.67, 383.33, 843.33, 2108.33, 6325.0, 25300.0, 177100.0],
  [1.39, 1.96, 2.82, 4.13, 6.2, 9.54, 15.1, 24.72, 42.02, 74.7, 140.06, 280.13, 606.94, 1456.67, 4005.83, 13352.78, 60087.5, 480700.0],
  [1.47, 2.21, 3.38, 5.32, 8.59, 14.31, 24.72, 44.49, 84.04, 168.08, 360.16, 840.38, 2185.0, 6555.0, 24035.0, 120175.0, 1081575.0],
  [1.56, 2.5, 4.11, 6.95, 12.16, 22.12, 42.02, 84.04, 178.58, 408.19, 1020.47, 2857.31, 9286.25, 37145.0, 204297.5, 2042975.0],
  [1.67, 2.86, 5.05, 9.27, 17.69, 35.38, 74.7, 168.08, 408.19, 1088.5, 3265.49, 11429.23, 49526.67, 297160.0, 3268760.0],
  [1.79, 3.3, 6.32, 12.64, 26.54, 58.97, 140.06, 360.16, 1020.47, 3265.49, 12245.6, 57146.15, 371450.0, 4457400.0],
  [1.92, 3.85, 8.04, 17.69, 41.28, 103.21, 280.13, 840.38, 2857.31, 11429.23, 57146.15, 400023.08, 5200300.0],
  [2.08, 4.55, 10.45, 25.56, 67.08, 191.67, 606.94, 2185.0, 9286.25, 49526.67, 371450.0, 5200300.0],
  [2.27, 5.45, 13.94, 38.33, 115.0, 383.33, 1456.67, 6555.0, 37145.0, 297160.0, 4457400.0],
  [2.5, 6.67, 19.17, 60.24, 210.83, 843.33, 4005.83, 24035.0, 204297.5, 3268760.0],
  [2.78, 8.33, 27.38, 100.4, 421.67, 2108.33, 13352.78, 120175.0, 2042975.0],
  [3.12, 10.71, 41.07, 180.71, 948.75, 6325.0, 60087.5, 1081575.0],
  [3.57, 14.29, 65.71, 361.43, 2530.0, 25300.0, 480700.0],
  [4.17, 20.0, 115.0, 843.33, 8855.0, 177100.0],
  [5.0, 30.0, 230.0, 2530.0, 53130.0],
  [6.25, 50.0, 575.0, 12650.0],
  [8.33, 100.0, 2300.0],
  [12.5, 300.0],
  [25.0],
]

export { statuses, cookiesSettings, cookiesCount, coeffs };