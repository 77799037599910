import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { actions } from './';

import SwitchBox from 'components/SwitchBox';
import settingsSvg from './img/settings.svg';

import './Settings.scss';

const b = block('settings');

const Settings = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { isMusic } = useSelector(state => state.settings);
  const locale = useSelector(state => state.locale.locale);

  const switchMusic = useCallback(value => dispatch(actions.setMusic(value)), [dispatch]);

  return <article className={b({ isOpen })}>
    <SVGInline svg={settingsSvg} className={b('icon', { isOpen }).toString()} onClick={() => setOpen(!isOpen)} />
    <div className={b('row')}>
      <span className={b('text')}>{locale.music}</span>
      <SwitchBox value={isMusic} changeValue={value => switchMusic(value)} />
    </div>
  </article>
}

export default Settings;
