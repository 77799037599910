import initialState from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        limits: { min: payload.min_bet, max: payload.max_bet, win: payload.max_win },
        coeffs: state.coeffs.map(t => t.map(t1 => t1 * payload.rtp_coefficient).map(tempCoef => {
          if (tempCoef < 1) {
            return 1
          } else if (tempCoef < 10) {
            return Math.floor(tempCoef * 100) / 100
          } else if (tempCoef < 100) {
            return Math.floor(tempCoef * 10) / 10
          } else if (tempCoef < 1000) {
            return Math.floor(tempCoef)
          } else if (tempCoef < 1000000) {
            return Math.floor(tempCoef / 10) * 10 // Further last 3 sumbols will replace to 'k'
          } else {
            return Math.floor(tempCoef / 10000) * 10000 // Further last 6 sumbols will replace to 'M'
          }
        })),
        rtp: payload.rtp_coefficient,
      }

    case actionTypes.START_GAME:
      return {
        ...state,
        placedAmount: payload.amount,
        temp_pick: initialState.temp_pick,
        roundID: payload.game_id,
        gameStatus: 1,
      }

    case actionTypes.SET_START_INFO: {
      const isUnfinished = Object.keys(payload.unfinished_game)?.length !== 0;
      const isFinished = Object.keys(payload.finished_game)?.length !== 0;

      if (!isUnfinished && !isFinished) {
        return { ...state, gameStatus: 0 };
      }

      const brokenCookiesIndexes = isUnfinished ? [] : JSON.parse(payload.finished_game.broken_cookies_indexes);
      const pickedIndexes = isUnfinished ? payload.unfinished_game.picked_indexes : JSON.parse(payload.finished_game.picked_indexes);
      const brokenCookies = isUnfinished ? payload.unfinished_game.broken_cookies_count : payload.finished_game.broken_cookies_count;
      const placedAmount = isUnfinished ? payload.unfinished_game.amount : payload.finished_game.amount;

      const newState = {
        placedAmount,
        gameSettings: { ...state.gameSettings, brokenCookies },
        temp_pick: initialState.temp_pick.split('').map((_, index) => brokenCookiesIndexes.includes(index)
          ? pickedIndexes.includes(index) ? '3' : '2'
          : pickedIndexes.includes(index) ? '1' : '0'
        ).join(''),
        gameStatus: isUnfinished ? 1 : 2,
      };

      return { ...state, gameStatus: 0, ...newState };
    }

    case actionTypes.SET_IS_PROCESS:
      return { ...state, isProcess: payload }

    case actionTypes.SET_GAME_SETTINGS: {
      return {
        ...state,
        gameSettings: { ...state.gameSettings, ...payload },
      }
    }

    case actionTypes.PICK:
      return {
        ...state,
        temp_pick: payload,
      }

    case actionTypes.COMPLETE_GAME:
      return {
        ...state,
        gameStatus: 0,
        temp_pick: payload.temp_pick,
      }

    case actionTypes.LOST:
      return {
        ...state,
        gameStatus: 2,
        temp_pick: payload,
      }

    default: return state;
  }
}