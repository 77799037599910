import initialState from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_MY_BETS:
      return {
        ...state,
        myBets: payload.games,
      }

    case actionTypes.GET_TOP_WINS_SUCCESS:
      return {
        ...state,
        topWins: payload.top_wins,
      }  

    case actionTypes.GET_PLAYERS_BETS: {
      return { ...state, bets: [...state.bets, payload].sort((a, b) => b.bet_amount - a.bet_amount) }
    }

    case actionTypes.UPDATE_PLAYERS_BETS: {
      const newBets = state.bets.find(t => t.bet_id === payload.bet_id) !== undefined ?
      state.bets.map(t => t.bet_id !== payload.bet_id ? t : ({
        ...t,
        ...payload,
        isWin: true,
      })) : [...state.bets, { ...payload, isWin: true, bet_amount: (payload.win_amount / payload.coefficient).toFixed(2) }]
      return { ...state, bets: newBets }
    }

    case actionTypes.REMOVE_PLAYERS_BETS: {
      return {
        ...state,
        bets: state.bets.filter(t => t.bet_id !== payload)
      }
    }

    case actionTypes.CLEAR_BETS:
      return {
        ...state,
        bets: [],
      }

    case actionTypes.SET_IS_PROCESS:
      return { ...state, isProcess: payload }

    case actionTypes.UPDATE_BET:
      return {
        ...state, myBets: state.myBets.map(t => t.game_id === payload.roundID ? { ...t, win_amount: payload.win_amount } : t)
      }  

    default: return state;
  }
}