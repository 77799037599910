import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Subheader.scss';

const b = block('subheader');

const Subheader = () => {
  const locale = useSelector(state => state.locale.locale);
  const currency = useSelector(state => state.auth.currency);
  const { limits } = useSelector(state => state.game);

  return <article className={b()}>
      <div className={b('info-item')}>
        <div className={b('info-title')}>{locale.minBet}:</div>
        <div className={b('info-value')}>
          {`${(+limits.min).toFixed(2)} `}
          <span>{currency}</span>
        </div>
      </div>
      <div className={b('info-item')}>
        <div className={b('info-title')}>{locale.maxBet}:</div>
        <div className={b('info-value')}>
          {`${(+limits.max).toFixed(2)} `}
          <span>{currency}</span>
        </div>      </div>
      <div className={b('info-item')}>
        <div className={b('info-title')}>{locale.maxWin}:</div>
        <div className={b('info-value')}>
          {`${(+limits.win).toFixed(2)} `}
          <span>{currency}</span>
        </div>
      </div>
  </article>
}

export default Subheader;