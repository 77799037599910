import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import closePng from './img/close.png';

import './Rules.scss';

const Rules = ({ close }) => {
  const b = block('rules');
  const currency = useSelector(state => state.auth.currency);
  const { limits } = useSelector(state => state.game);
  const locale = useSelector(state => state.locale.locale)

  return <article className={b()}>
    <div className={b('bg')}>
      <div className={b('wrapper')}>
        <div className={b('content')}>
          <div className={b('header')}>
            <img className={b('close')} alt="" src={closePng} onClick={close} />
          </div>
          <div className={b('body')}>
            <div className="popup-rules-title-1">{locale.title1}</div>
            <div className="popup-rules-title-2 pn-semibold">{locale.title2}</div>
            <div className="popup-rules-title-3 pn-semibold"></div>
            <div className="popup-rules-title-4">{locale.title4}</div>
            <div className="popup-rule-bullets pn-semibold">
              <div className="popup-rule-bullets-left">
              </div>
              <div className="popup-rule-bullets-right">
              </div>
            </div>
            <div className="popup-rules-limits-title pn-semibold">{locale.limits}</div>
            <div className="popup-rules-limits-table">
              <div className="popup-rules-limits-header">
                <div className="popup-rule-limit-header-item">{locale.currency}</div>
                <div className="popup-rule-limit-header-item">{locale.minBet}</div>
                <div className="popup-rule-limit-header-item">{locale.maxBet}</div>
                <div className="popup-rule-limit-header-item">{locale.maxProfit}</div>
              </div>
              <div className="popup-rules-limits-body">
                <div className="popup-rule-limit-body-item">{currency}</div>
                <div className="popup-rule-limit-body-item">{`${(+limits.min).toFixed(2)} `}</div>
                <div className="popup-rule-limit-body-item">{`${(+limits.max).toFixed(2)} `}</div>
                <div className="popup-rule-limit-body-item">{`${(+limits.win).toFixed(2)} `}</div>
              </div>
            </div>
            <div className="popup-rules-rtp-bullet pn-semibold"></div>
          </div>
        </div>
      </div>
    </div>
  </article>
}

export default Rules;