import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SHOW_SOCKET_GAME_ERROR:
      return {
        ...state,
        isGameSocketError: true,
      }

    case actionTypes.SHOW_SOCKET_CHAT_ERROR:
      return {
        ...state,
        isChatSocketError: true
      }

    default: return state;
  }
}