import React from 'react';
import block from 'bem-cn';

import loaderGif from './img/loader.gif';

import './Loader.scss';

const b = block('loader');

const Loader = () => {
    return (
        <div className={b()}>
            <img className={b('loader-gif')} src={loaderGif} alt="" />
        </div>
    );
};

export default Loader;