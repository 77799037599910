import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import { actions } from '../';

import Input from 'components/Input';
import Messages from './Messages';
import Loader from 'components/Loader';

import openPng from './img/chat-open.png';
import sendPng from './img/send.png';

import './Chat.scss';

const b = block('chat');

const Chat = ({ switchIsOpenChat, isOpenChat }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const locale = useSelector(state => state.locale.locale);
  const { isChatSocketError } = useSelector(state => state.socketError);

  const sendMessage = useCallback(() => {
    if (text.length > 0) dispatch(actions.sendMessage(text, () => setText('')))
  }, [dispatch, text])

  const textValidator = useCallback(value => setText(value.substring(0, 60)), []);

  const sendMessageOnEnterClick = useCallback(e => {
    if (e.keyCode === 13 && text.length > 0) dispatch(actions.sendMessage(text, () => setText('')))
  }, [dispatch, text])

  return <article className={b()}>
    <div className={b('top-block')}>
      {`${locale.chat}`}
      <div className={b('switcher')}>
        <img src={openPng} className={b('open-icon', { isOpen: isOpenChat }).toString()} alt="" onClick={switchIsOpenChat} />
      </div>
    </div>
    {isChatSocketError ? <Loader /> : <>
      <div className={b('messages')}>
        <Messages />
      </div>
      <div className={b('input-container')}>
        <Input
          type="textarea"
          value={text}
          placeholder={`${locale.enterMessage}`}
          callBack={textValidator}
          onKeyDown={sendMessageOnEnterClick}
        />
        <div className={b('submit')} onClick={sendMessage}>
          <img
            src={sendPng}
            alt=""
          />
        </div>
      </div>
    </>}
  </article>
}

export default Chat;