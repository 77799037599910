import BaseApi from '../BaseApi';

class HistoryApi extends BaseApi {
  constructor(baseUrl) {
    super();
    this.baseUrl = `api/chicken`;
  }

  getTopWins = () => this.sendQuery(
    this.queryTypes.GET,
    `${this.baseUrl}/top_wins`,
  )

  getMyBets = (count = 10, token) => this.sendQuery(
    this.queryTypes.GET,
    `${this.baseUrl}/game_history?quantity=${count}&token=${token}`,
  )
}

export default HistoryApi;