import { actions as authActions } from 'features/Auth';
import { actions as historyActions } from 'features/History';
import { statuses } from '../data';

const actionTypes = {
  GET_SETTINGS_SUCCESS: 'game/GET_SETTINGS_SUCCESS',
  SET_START_INFO: 'game/SET_START_INFO',
  COMPLETE_GAME: 'game/COMPLETE_GAME',
  SET_IS_PROCESS: 'game/SET_IS_PROCESS',
  START_GAME: 'game/START_GAME',
  SET_GAME_SETTINGS: 'game/SET_GAME_SETTINGS',
  PICK: 'game/PICK',
  WON: 'game/WON',
  LOST: 'game/LOST',
  GET_ROUND_ID: 'game/GET_ROUND_ID',
  GET_WIN_CARD: 'game/GET_WIN_CARD',
  GET_COEFS_HISTORY: 'game/GET_COEFS_HISTORY',
  GET_TOP_WINS: 'game.GET_TOP_WIND',
  SET_ROUND_INFO: 'game/SET_ROUND_INFO',
  GET_INFO: 'game/GET_INFO',
  GET_CASHED_OUT: 'game/GET_CASHED_OUT',
  SET_BET_AMOUNT_IN_ROUND: 'game/SET_BET_AMOUNT_IN_ROUND',
}

const getSettings = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.GET_SETTINGS_SUCCESS, payload: data });
}

const setStartInfo = data => dispatch => {
  dispatch({ type: actionTypes.SET_START_INFO, payload: data })
}

const startGame = callback => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { token } = getState().auth;
  const { isProcess, gameSettings, temp_pick } = getState().game;
  if (!isProcess) {
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
    const response = await api.game.startGame({
      amount: gameSettings.amount,
      brokenCookies: gameSettings.brokenCookies,
      pre_pick: { 
        is_pre_picked: gameSettings.isAutoPick,
        pre_pick_data: gameSettings.autoPickData
      } }, token);
    if (response.success) {
      if (!gameSettings.isAutoPick) {
        dispatch({ type: actionTypes.START_GAME, payload: { ...gameSettings, game_id: response.data.game_id } })
        dispatch(authActions.getBalance(response.data.new_balance))
      } else {
        dispatch(authActions.getBalance(response.data.new_balance));
        if (response.data?.status === 1) {
          dispatch({ type: actionTypes.COMPLETE_GAME, payload: { temp_pick: temp_pick.split('').map((t, index) =>
            response.data.broken_cookies.findIndex(broken => broken === index) !== -1 ? '2' :
            gameSettings.autoPickData.findIndex(broken => broken === index) !== -1 ? '1' : '0').join('') } })
          dispatch(authActions.getBalance(response.data.new_balance))
        }
        if (response.data?.status === 2) {
          dispatch({ type: actionTypes.LOST, payload: temp_pick.split('').map((t, index) =>
          response.data.broken_cookies.findIndex(broken => broken === index) !== -1 ? 
          gameSettings.autoPickData.findIndex(broken => broken === index) !== -1 ? '3' : '2' :
          gameSettings.autoPickData.findIndex(broken => broken === index) !== -1 ? '1' : '0').join('') });
        }
      }

      if (callback) {
        callback();
      }
    } 
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
  }
}

const completeGame = callback => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { token } = getState().auth;
  const { roundID, isProcess, temp_pick } = getState().game;
  if (!isProcess) {
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
    const response = await api.game.completeGame({ round_id: roundID }, token);
    if (response.success) {
      dispatch({ type: actionTypes.COMPLETE_GAME, payload: { temp_pick: response.data.broken_cookies.reduce((string, t) => string.split('').map((t1, index) => index === t ? '2' : t1).join(''), temp_pick) } })
      dispatch(authActions.getBalance(response.data.new_balance));
      dispatch(historyActions.updateBet({ roundID, win_amount: +response.data.win_amount || 0 }))
      if (callback) {
        callback();
      }
    } 
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
  }
}

const pick = data => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { isProcess, temp_pick } = getState().game;
  const { token } = getState().auth;
  if (!isProcess) {
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
    const response = await api.game.pick({ temp_pick: data }, token);
    if (response.success) {
      const responseData = response.data;
      switch (responseData.status) {
        case statuses.inPlay:
          {
            if (responseData.is_correct) {
              dispatch({ type: actionTypes.PICK, payload: temp_pick.split('').map((t, i) => i === data ? '1' : t).join('') });
            }
            break;
          }
        case statuses.lost:
          {
            dispatch({ type: actionTypes.LOST, payload:
              responseData.broken_cookies.reduce((string, t) =>
              string.split('').map((t1, index) => index === t ? data === t ? '3' : '2' : t1).join(''), temp_pick) });
            break;
          }             
        default: break;  
      }
    } 
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
  }
}

const setGameSettings = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_GAME_SETTINGS, payload: data });
}

const cashoutBet = (bet_id, setPlaced) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { token } = getState().auth;
  const { roundID, coef } = getState().game;
  const response = await api.game.cashoutBet(token, { game_id: roundID, current_point: +coef, bet_id });
  if (response.success) {
    setPlaced(false);
  } 
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}
const cancelBet = (bet_id, setPlaced) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { token } = getState().auth;
  const { roundID } = getState().game;
  const response = await api.game.cancelBet(token, { game_id: roundID + 1, bet_id });
  if (response.success) {
    setPlaced(false);
    const canceledBet = getState().history.bets.find(t => t.bet_id === bet_id);
    const { betAmountInRound } = getState().game;
    dispatch({ type: actionTypes.SET_BET_AMOUNT_IN_ROUND, payload: betAmountInRound - canceledBet.bet_amount })
    dispatch(historyActions.removePlayersBets(bet_id))
    dispatch(authActions.getBalance(+response.data.new_balance));
  } 
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}
const placeBet = (amount, coef, isAutoCashout, setPlaced, isAutoBet, index) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { token } = getState().auth;
  const { roundID } = getState().game;
  const { max } = getState().game.limits;
  const { betAmountInRound } = getState().game;
  if (betAmountInRound + amount <= max) {
    const placeFunc = async() => {
      dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
      const response = await api.game.placeBet(token, { bet_amount: amount, game_id: roundID + 1, auto_cashout: isAutoCashout ? { point: coef } : undefined });
      if (response.success) {
        dispatch({ type: actionTypes.SET_BET_AMOUNT_IN_ROUND, payload: amount })
        dispatch(authActions.getBalance(response.data.new_balance));
        setPlaced(response.data.bet_id);
      } 
      dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
    }
    setTimeout(() => placeFunc(), isAutoBet ? Math.trunc(Math.random() * 1000 + index * 1000) : 0)
  } 
}
export {
  actionTypes,
  cancelBet,
  placeBet,
  cashoutBet,
  getSettings,
  setStartInfo,
  setGameSettings,
  pick,
  startGame,
  completeGame,
}