const actionTypes = {
  SHOW_SOCKET_GAME_ERROR: 'socketError/SHOW_SOCKET_GAME_ERROR',
  SHOW_SOCKET_CHAT_ERROR: 'socketError/SHOW_SOCKET_CHAT_ERROR',
}

const showGameSessionError = () => async (dispatch, getState, extra) => {
    extra.socket.handleSessionClose();
    dispatch({ type: actionTypes.SHOW_SOCKET_GAME_ERROR });
}

const showGameTokenError = () => async (dispatch, getState, extra) => {
    extra.socket.handleSessionClose();
    dispatch({ type: actionTypes.SHOW_SOCKET_GAME_ERROR });
}

const showChatSessionError = () => async (dispatch, getState, extra) => {
  extra.chatSocket.handleSessionClose();
  dispatch({ type: actionTypes.SHOW_SOCKET_CHAT_ERROR });
}

const showChatTokenError = () => async (dispatch, getState, extra) => {
  extra.chatSocket.handleSessionClose();
  dispatch({ type: actionTypes.SHOW_SOCKET_CHAT_ERROR });
}

export {
  actionTypes,
  showGameSessionError,
  showGameTokenError,
  showChatSessionError,
  showChatTokenError,
}
