const categories = {
  myBets: {
    id: 0,
    textIdent: 'myBets',
  },
  results: {
    id: 1,
    textIdent: 'topWins',
  },
}

export { categories };