import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Messages.scss';

const b = block('messages');

const Messages = () => {
  const messagesRef = useRef();
  const isOpen = useState(false);
  const messages = useSelector(state => state.chat.messages);

  useEffect(() => {
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  }, [messages.length])

  const messagesList = useMemo(() => [...messages].reverse().map(t => <div key={t.time + t.userID} className={b('item')}>
      <span className={b('nick')}>
        {`${(t.nick?.length > 0 ? t.nick : t.userID).toString()[0]}***${(t.nick?.length > 0 ? t.nick : t.userID).toString()[(t.nick?.length > 0 ? t.nick : t.userID).toString().length - 1]}: `}
      </span>
      <span className={b('text')}>
        {t.message}
      </span>
  </div>), [messages])
  
  return <div className={b({ isOpen })} ref={messagesRef}>
    {messagesList}
  </div>
}

export default Messages;