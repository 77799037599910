
const actionTypes = {
  GET_MY_BETS: 'history/GET_MY_BETS',
  GET_PLAYERS_BETS: 'history/GET_PLAYERS_BETS',
  GET_TOP_WINS_SUCCESS: 'history/GET_TOP_WINS_SUCCESS',
  SET_IS_PROCESS: 'history/SET_IS_PROCESS',
  UPDATE_PLAYERS_BETS: 'history/UPDATE_PLAYERS_BETS',
  REMOVE_PLAYERS_BETS: 'history/REMOVE_PLAYERS_BETS',
  UPDATE_BET: 'history/UPDATE_BET',
  CLEAR_BETS: 'game/CLEAR_BETS',
}

const getMyBets = () => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true })
  const { api } = extra;
  const { token } = getState().auth;
  const response = await api.history.getMyBets(30, token);
  if (response.success) {
    dispatch({ type: actionTypes.GET_MY_BETS, payload: response.data });
  } else {
    // dispatch(addNotify(locale.error, 'error'));
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const getPlayersBets = data => dispatch => {
  dispatch({ type: actionTypes.GET_PLAYERS_BETS, payload: data })
}

const updatePlayersBets = data => dispatch => dispatch({ type: actionTypes.UPDATE_PLAYERS_BETS, payload: data })

const removePlayersBets = data => dispatch => dispatch({ type: actionTypes.REMOVE_PLAYERS_BETS, payload: data })

const clearBets = () => ({ type: actionTypes.CLEAR_BETS })

const getTopWins = () => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true })
  const { api } = extra;
  const response = await api.history.getTopWins();
  if (response.success) {
    dispatch({ type: actionTypes.GET_TOP_WINS_SUCCESS, payload: response.data });
  } else {
    // dispatch(addNotify(locale.error, 'error'));
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const updateBet = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.UPDATE_BET, payload: data })
}

export {
  actionTypes,
  getMyBets,
  getPlayersBets,
  clearBets,
  getTopWins,
  updatePlayersBets,
  removePlayersBets,
  updateBet,
}