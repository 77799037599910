const actionTypes = {
  SET_SOUND: 'SET_SOUND',
  SET_MUSIC: 'SET_MUSIC',
  SET_ANIMATIONS: 'SET_ANIMATIONS',
}

const setMusic = value => async dispatch => dispatch({ type: actionTypes.SET_MUSIC, payload: value });

export {
  actionTypes,
  setMusic,
}