import React from 'react';
import block from 'bem-cn';

import './SwitchBox.scss';

const b = block('switch-box');

const SwitchBox = ({value, changeValue, text }) => <div className={b({ active: value })} onClick={() => changeValue(!value)}>
  <div className={b('indicator', { active: value })} />
  {text ? text : null}
</div>

export default SwitchBox;