import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/Game';
import { cookiesSettings } from 'features/Game/data';
import Button from 'components/Button';
import Input from 'components/Input';
import minusPng from './img/minus.png';
import plusPng from './img/plus.png';
import arrowUpPng from './img/arrow-up.png';
import arrowDownPng from './img/arrow-down.png';
import './Basket.scss';
import { useMemo } from 'react';

const Basket = () => {
  const dispatch = useDispatch();
  const limits = useSelector(state => state.game.limits);
  const gameStatus = useSelector(state => state.game.gameStatus);
  const { brokenCookies, amount } = useSelector(state => state.game.gameSettings);
  const currency = useSelector(state => state.auth.currency);
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const platform = useSelector(state => state.auth.platform);
  const balance = useSelector(state => state.auth.balance);
  const isProcess = useSelector(state => state.game.isProcess);
  const coeffs = useSelector(state => state.game.coeffs);
  const placedAmount = useSelector(state => state.game.placedAmount);
  const temp_pick = useSelector(state => state.game.temp_pick);
  const b = block(platform === 'mobile' ? 'basket' : 'basket-desktop');

  const isPlaced = useMemo(() => gameStatus === 1, [gameStatus]);
  const setAmount = useCallback(amount => dispatch(actions.setGameSettings({ amount })), [dispatch]);
  const setBrokenCookies = useCallback(brokenCookies => dispatch(actions.setGameSettings({ brokenCookies })), [dispatch]);


  //Set limits
  useEffect(() => setAmount(limits.min), [limits.min, setAmount])

  // Place bet function
  const placeBet = useCallback(() => {
    dispatch(actions.startGame())
  }, [dispatch])

  const completeGame = useCallback(() => {
    dispatch(actions.completeGame())
  }, [dispatch])

  // For validate amount
  const validateChangeAmount = useCallback(value => {
    if (!isPlaced) {
      if (value > limits.max) setAmount(+limits.max.toFixed(1))
      else if (value < limits.min) setAmount(+limits.min.toFixed(1))
      else if (+(+value).toFixed(1) > balance) {
        if (balance < limits.min) {
          setAmount(+limits.min.toFixed(1))
        } else {
          setAmount(Math.floor(balance * 10, 1) / 10)
        }
      }
      else setAmount(+(+value).toFixed(1));
    }
  }, [balance, isPlaced, limits.max, limits.min, setAmount])

  // For validate input Amount
  const validateTempAmount = useCallback(value => {
    if (!isPlaced) {
      if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
        value.toString().split('').filter(t => t === '.').length < 2) {
        if (value.toString().split('')[0] === '.') {
          setAmount(value.substring(1, value.length))
        } else setAmount(value)
      }
    }
  }, [isPlaced, setAmount])

  const correctsLength = useMemo(() => temp_pick.split('').filter(i => i === '1').length, [temp_pick])

  const possibleAmount = useMemo(() => ((coeffs[brokenCookies - 1]?.[correctsLength - 1] || 1) * placedAmount)?.toFixed(2), [placedAmount, brokenCookies, coeffs, correctsLength])

  // Set button text
  const tempButtonText = useMemo(() => {
    if (isPlaced) {
      return <div className={b('cashout-bet')}>
        <div className={b('cashout-text')}>{locale.cashout}</div>
        <div className={b('cashout-content')}>
          <div className={b('cashout-content')}>
            <span className={b('cashout-amount')}>{`${isPlaced && correctsLength ? possibleAmount : ' '} `}</span>
            <span className={b('cashout-currency')}>{`${isPlaced && correctsLength ? currency : ' '} `}</span>
          </div>
        </div>
      </div>
    }
    if (!isPlaced) {
      return locale.placeBet
    }
  },
    [isPlaced, possibleAmount, b, currency, locale.cashout, locale.placeBet, temp_pick]
  )

  const validateSetBrokenCookiesMinus = useCallback(() => {
    if ((cookiesSettings.min < brokenCookies) && !isPlaced) {
      setBrokenCookies(+brokenCookies - 1)
    }
  }, [brokenCookies, isPlaced, setBrokenCookies]);

  const validateSetBrokenCookiesPlus = useCallback(() => {
    if ((brokenCookies < cookiesSettings.max) && !isPlaced) {
      setBrokenCookies(+brokenCookies + 1)
    }
  }, [brokenCookies, isPlaced, setBrokenCookies]);


  const buttonFunc = useMemo(() => isPlaced ? completeGame : placeBet, [completeGame, isPlaced, placeBet])

  return platform === 'mobile' ?
    // MOBILE
    <article className={b({ isPlaced })}>
      <div className={b('rows')}>
        <div className={b('row')}>
          <div className={b('change-block')}>
            <div className={b('title')}>{locale.placeBet}</div>
            <div className={b('change-elements')}>
              <div className={b('input')}>
                <Input
                  value={amount}
                  onChange={e => validateTempAmount(e.currentTarget.value)}
                  onBlur={() => validateChangeAmount(amount)}
                  unBorder />
                <div className={b('overlay')}>
                  <div className={b('input-val')}>{amount}</div>
                  <div className={b('input-val-ending')}>{currency}</div>
                </div>
              </div>
              <div className={b('change-button')} onClick={() => validateChangeAmount(amount - limits.min)}>
                <img src={minusPng} alt="" />
              </div>
              <div className={b('change-button')} onClick={() => validateChangeAmount(amount + limits.min)}>
                <img src={plusPng} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={b('row')}>
          <div className={b('change-block')}>
            <div className={b('title')}>{locale.bones}</div>
            <div className={b('change-elements')}>
              <div className={b('input')}>
                <Input
                  value={brokenCookies}
                  onChange={setBrokenCookies}
                  unBorder />
                <div className={b('overlay')}>
                  <div className={b('input-val')}>{brokenCookies}</div>
                </div>
              </div>
              <div className={b('change-button')} onClick={validateSetBrokenCookiesMinus}>
                <img src={arrowDownPng} alt="" />
              </div>
              <div className={b('change-button')} onClick={validateSetBrokenCookiesPlus}>
                <img src={arrowUpPng} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={b('button-layout')}>
        <Button
          text={tempButtonText}
          size='container'
          fontSize="large"
          isDisabled={isProcess}
          color={isPlaced ? 'red' : 'green'}
          isBlock={isPlaced}
          isRobotoFont={!isPlaced && gameStatus === 1}
          isBold={!isPlaced && gameStatus === 1}
          callBack={buttonFunc} />
      </div>
    </article> :
    // DESKTOP
    <article className={b({ isPlaced, lang })}>
      <div className={b('container')}>
        <div className={b('rows')}>
          <div className={b('row')}>
            <div className={b('change-block')}>
              <div className={b('title')}>{locale.placeBet}</div>
              <div className={b('change-elements')}>
                <div className={b('input')}>
                  <Input
                    value={amount}
                    onChange={e => validateTempAmount(e.currentTarget.value)}
                    onBlur={() => validateChangeAmount(amount)}
                    unBorder />
                  <div className={b('overlay')}>
                    <div className={b('input-val')}>{amount}</div>
                    <div className={b('input-val-ending')}>{currency}</div>
                  </div>
                </div>
                <div className={b('change-button')} onClick={() => validateChangeAmount(amount - limits.min)}>
                  <img src={minusPng} alt="" />
                </div>
                <div className={b('change-button')} onClick={() => validateChangeAmount(amount + limits.min)}>
                  <img src={plusPng} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={b('row')}>
            <div className={b('change-block')}>
              <div className={b('title')}>{locale.bones}</div>
              <div className={b('change-elements')}>
                <div className={b('input')}>
                  <Input
                    value={brokenCookies}
                    onChange={setBrokenCookies}
                    unBorder
                  />
                  <div className={b('overlay')}>
                    <div className={b('input-val')}>{brokenCookies}</div>
                  </div>
                </div>
                <div className={b('change-button')} onClick={validateSetBrokenCookiesMinus}>
                  <img src={arrowDownPng} alt="" />
                </div>
                <div className={b('change-button')} onClick={validateSetBrokenCookiesPlus}>
                  <img src={arrowUpPng} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={b('button-layout')}>
        <Button
          text={tempButtonText}
          size='container'
          fontSize="large"
          isDisabled={isProcess}
          color={isPlaced ? 'red' : 'green'}
          isBlock={isPlaced}
          isRobotoFont={!isPlaced && gameStatus === 1}
          isBold={!isPlaced && gameStatus === 1}
          callBack={buttonFunc} />
      </div>
    </article>
}

export default Basket;