import BaseApi from '../BaseApi';

class GameApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/chicken`;
  }

  startGame = (data, token) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/start_game?token=${token}`,
    data,
  )

  completeGame = (data, token) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/complete_game?token=${token}`,
    data,
  )

  pick = (data, token) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/pick?token=${token}`,
    data,
  )
}

export default GameApi;