import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as settingsActions } from 'features/Settings';

import musicOnPng from './img/music-on.png';
import musicOffPng from './img/music-off.png';

import './MusicWindow.scss';

const b = block('music-window');

const MusicWindow = ({ closeMusicWindow }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);

  const setIsMusic = useCallback(value => {
    dispatch(settingsActions.setMusic(value))
    closeMusicWindow();
  }, [dispatch, closeMusicWindow])

  return <div className={b()}>
    <div className={b('container')}>
      <div className={b('title')}>
        {locale.likeSound}
      </div>
      <div className={b('buttons')}>
        <div className={b('button')} onClick={() => setIsMusic(true)}>
          {locale.yes}
          <img className={b('icon')} src={musicOnPng} alt="" />
        </div>
        <div className={b('button')} onClick={() => setIsMusic(false)}>
          {locale.no}
          <img className={b('icon')} src={musicOffPng} alt="" />
        </div>
      </div>
    </div>
  </div>
}

export default MusicWindow;
