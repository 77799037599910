import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import BasketModel from 'components/Basket';
import Subheader from 'components/Subheader';
import { History } from 'features/History';
import { Chat } from 'features/Chat';
import GameView from 'components/GameView';
import Winning from 'components/Winning';


import './Game.scss';


const b = block('game');

const Game = () => {
  const platform = useSelector(state => state.auth.platform);

  const [isOpenChat, setIsOpenChat] = useState(false);

  const switchIsOpenChat = useCallback(() => setIsOpenChat(!isOpenChat), [isOpenChat])

  return <article className={b({ platform })}>
    <div className={b('left')}>
      <div className={b('subheader-layout')}>
        <Subheader />
      </div>
      <div className={b('game-layout')}>
        <GameView />
      </div>
      <div className={b('basket-layout')}>
        <BasketModel />
      </div>
      { platform === 'mobile' ? <div className={b('winning-layout')}><Winning /></div> : null }
    </div>
    <div className={b('right')}>
      <div className={b('history-layout', { isOpenChat })}>
        <History />
      </div>
      <div className={b('chat-layout')}>
        <Chat switchIsOpenChat={switchIsOpenChat} isOpenChat={isOpenChat} />
      </div>
    </div>
  </article>
}

export default Game;