import { actionTypes } from './actions';
import initialState from './initial';

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case actionTypes.SET_MUSIC: return { ...state, isMusic: payload };

    default: return state;
  }
}

export default reducer;