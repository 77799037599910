import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { categories } from './data';

import Button from 'components/Button';
import MyBets from './MyBets';
import TopWins from './TopWins';

import './History.scss';

const History = ({ isClosingHistory }) => {
  const b = block('history');
  const [category, changeCategory] = useState(categories.myBets || '')
  const locale = useSelector(state => state.locale.locale)
  const bets = useSelector(state => state.history.bets) || []

  const buttons = Object.entries(categories).map(t => (
    <div className={b('button-container')} key={t[0]}>
      <Button
        text={t[0] === 'bets' && bets.length > 0 ? <div className={b('section-title')}>
          {locale[t[1].textIdent]}
          <span className={b('bets-amount')}>{bets.length}</span>
        </div> : locale[t[1].textIdent]}
        size='container'
        color={category.id === t[1]?.id ? 'dark' : 'transparent'}
        fontSize='small'
        callBack={() => changeCategory(t[1])} />
    </div>
  ))

  const neededComponent = useMemo(() => {
    switch (category.id) {
      case 0:
        return <MyBets />;
      case 1:
        return <TopWins />;
      default:
        return null;
    }
  }, [category.id])

  return <article className={b({ isClosingHistory })}>
    <div className={b('buttons-container')}>
      {buttons}
    </div>
    <div className={b('content-container')}>
      {neededComponent}
    </div>
  </article>
}

export default History;