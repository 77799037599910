import { cookiesSettings, cookiesCount, coeffs } from '../data';

export default {
  roundID: 0,
  isProcess: false,
  gameStatus: 0,
  limits: {
    min: 0,
    max: 0,
    win: 0,
  },
  gameSettings: {
    brokenCookies: cookiesSettings.min + 1,
    amount: 0,
    isAutoPick: false,
    autoPickData: [],
  },
  temp_pick: ''.padStart(cookiesCount, '0'),
  missCookie: null,
  placedAmount: 0,
  coeffs: coeffs,
  rtp: 1,
}