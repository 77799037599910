import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Winning.scss';

const b = block('winnings');

const Winning = () => {
  const locale = useSelector(state => state.locale.locale);
  const { brokenCookies } = useSelector(state => state.game.gameSettings);
  const coeffs = useSelector(state => state.game.coeffs);
  const placedAmount = useSelector(state => state.game.placedAmount);
  const temp_pick = useSelector(state => state.game.temp_pick);
  const currency = useSelector(state => state.auth.currency);

  const correctsLength = useMemo(() => temp_pick.split('').filter(i => i === '1').length, [temp_pick]);

  const convertCoeffs = useMemo(() => coeffs.map(t => t.map(t1 => {
    if (t1 < 1000) {
      return t1
    } else if (t1 < 1000000) {
      return `${t1 / 1000}K`
    } else {
      return `${t1 / 1000000}M`
    }
  })), [coeffs]);

  return (
    <div className={b()}>
      <div className={b('container')} >
        <div className={b('item')}>
          <div className={b('item-content')}>
            <span className={b('title')}>{locale.nextRoundWin}</span>
            <div className={b('bottom-block')}>
              <span className={b('coef')}>{convertCoeffs[brokenCookies - 1]?.[correctsLength]}x</span>
              <span className={b('amount')}>
                {`${+((convertCoeffs[brokenCookies - 1]?.[correctsLength]) * placedAmount)?.toFixed(2)} `}
                <b>{currency}</b>
              </span>
            </div>
          </div>
        </div>
        <div className={b('item')}>
          <div className={b('item-content')}>
            <span className={b('title')}>{locale.totalWin}</span>
            <div className={b('bottom-block')}>
              <span className={b('coef')}>{convertCoeffs[brokenCookies - 1]?.[correctsLength - 1] || '1.00'}x</span>
              <span className={b('amount')}>
                {`${+((convertCoeffs[brokenCookies - 1]?.[correctsLength - 1]) * placedAmount)?.toFixed(2) || placedAmount} `}
                <b>{currency}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Winning;