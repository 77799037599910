import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/Game';
import chicken from './img/big_chicken.png';
import bone from './img/big_bone.png';
import smallBoune from './img/small_bone.png';
import smallChicken from './img/small_chicken.png';
import Winning from 'components/Winning';

import './GameView.scss';

const b = block('game-view');

const GameView = () => {
  const dispatch = useDispatch();
  const platform = useSelector(state => state.auth.platform);
  const { brokenCookies, isAutoPick, autoPickData } = useSelector(state => state.game.gameSettings);
  const temp_pick = useSelector(state => state.game.temp_pick);
  const gameStatus = useSelector(state => state.game.gameStatus);

  const pick = useCallback(pickInfo => {
    const openedIndex = temp_pick.split('').reduce((newArray, t, index) => t !== '0' ? [...newArray, index] : [...newArray], [])
    if (openedIndex.findIndex(t => t === pickInfo) === -1) {
      dispatch(actions.pick(pickInfo))
    }
  }, [temp_pick, dispatch])

  // Auto pick
  const autoPickClick = useCallback(pickInfo => {
    if ((autoPickData.length < temp_pick.length - brokenCookies) || (autoPickData.length === temp_pick.length - brokenCookies && autoPickData.findIndex(t => t === +pickInfo) !== - 1)) {
      const resultArray = autoPickData.findIndex(t => t === +pickInfo) === - 1 ? [...autoPickData, +pickInfo] : autoPickData.filter(t => t !== +pickInfo)
      dispatch(actions.setGameSettings({ autoPickData: resultArray }))
    }
  }, [autoPickData, brokenCookies, dispatch, temp_pick.length])

  useEffect(() => {
    if (!isAutoPick) {
      dispatch(actions.setGameSettings({ autoPickData: [] }))
    }
  }, [dispatch, isAutoPick])

  const click = useMemo(() => {
    if (gameStatus === 1) {
      return pick;
    } else {
      if (isAutoPick) {
        return autoPickClick;
      } else {
        return f => f;
      }
    }
  }, [autoPickClick, gameStatus, isAutoPick, pick])

  const correctsLength = useMemo(() => temp_pick.split('').filter(i => i === '1').length, [temp_pick])

  // End game
  const isEndGame = useMemo(() => (gameStatus === 0 && temp_pick.split('').filter(t => t !== '0').length !== 0) || gameStatus === 2, [gameStatus, temp_pick])

  // Cookies
  const cookies = useMemo(() => temp_pick.split('').map((t, index) => {
    const isAutoPicked = autoPickData.findIndex(autoPick => autoPick === index) !== -1;
    return (
      <div
        className={b('cookie', {
          isAutoPick: isAutoPicked,
          isBlock: ((gameStatus === 0) || gameStatus === 2) && !isAutoPick,
          isClosed: (t === '0' && !isEndGame),
          isEndGame: isEndGame && (t === '0' || t === '2') })}
          key={index}
          onClick={() => click(index)}
      >
        {(t === '0' && gameStatus === 1) || (!isEndGame && gameStatus === 0)
          ? null
          : <img
              className={b('icon')}
              alt=""
              src={t === '1' || (t === '0' && (gameStatus === 0 || gameStatus === 2) && isEndGame) ? chicken : bone}
            />}
      </div>
    );
  }), [autoPickData, click, gameStatus, isEndGame, temp_pick, isAutoPick])

  // Good and bad cookies
  const goodCookiesCount = useMemo(() => temp_pick.length - brokenCookies - correctsLength, [correctsLength, temp_pick, brokenCookies])
  const badCookiesCount = useMemo(() => brokenCookies, [brokenCookies])

  return <article className={b()}>
    <div className={b('cookies-container')}>
      <div className={b('cookies')}>
        <div className={b('shadow')}>{cookies}</div>
      </div>
    </div>
    <div className={b('bones_chichens_count_conteiner')}>
      <div className={b('count_container')}>
        <div className={b('count')}>{badCookiesCount}</div>
        <div className={b('separator')}>x</div>
        <div className={b('icon_container')}>
          <img alt="" src={smallBoune} />
        </div>
      </div>
      <div className={b('count_container')}>
        <div className={b('count')}>{goodCookiesCount}</div>
        <div className={b('separator')}>x</div>
        <div className={b('icon_container')}>
          <img className={b('small-icon')} alt="" src={smallChicken} />
        </div>
      </div>
    </div>
    {platform === 'desktop' ? <Winning /> : null}
  </article>
}

export default GameView;